var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-2xl"},[_vm._v(" SMS Templates ")]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Nome","hide-details":"","outlined":"","dense":""},on:{"click:append":_vm.fechSmsTemplates,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fechSmsTemplates.apply(null, arguments)}},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-3"},[_c('v-btn',{staticClass:"pl-4",attrs:{"color":"primary"},on:{"click":_vm.fechSmsTemplates}},[_vm._v(" Pesquisar "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileEdit)+" ")])],1),_c('v-btn',{staticClass:"pl-4",attrs:{"color":"primary","outlined":""},on:{"click":_vm.openFormToCreate}},[_vm._v(" Novo Registro "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTablePlus)+" ")])],1)],1)],1),_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.rows,"item-key":"id","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","dark":"","text":"","title":"Editar","x-small":""},on:{"click":function($event){return _vm.openFormToEdit(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileEdit))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-2",attrs:{"color":_vm.resolveStatus(item.status).color,"small":"","label":""}},[_c('span',{staticClass:"white--text font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.resolveStatus(item.status).value)+" ")])])]}},{key:"item.variables",fn:function(ref){
var item = ref.item;
return _vm._l((['NUMERO' ].concat( item.variables)),function(variable,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"small":""}},[_c('span',{staticClass:"white--text font-weight-semibold"},[_vm._v(" "+_vm._s(variable)+" ")])])})}},{key:"item.serviceIds",fn:function(ref){
var item = ref.item;
return _vm._l((item.serviceIds),function(service,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"small":""}},[_c('span',{staticClass:"white--text font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.resolveServiceName(service))+" ")])])})}}],null,true)})],1)],1)],1),_c('validation-observer',{ref:"formSmsTemplate",attrs:{"autocomplete":"off","tag":"form"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchAction.apply(null, arguments)}}},[_c('dialog-form',{attrs:{"status":_vm.formDialogStatus,"is-update":_vm.formIsUpdate,"title":'SMS Template',"width":1200},on:{"action":_vm.fetchAction,"remove":function($event){_vm.removeDialogStatus = true},"close":function($event){_vm.formDialogStatus = !_vm.formDialogStatus}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required|max:100","vid":"form.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"Nome","outlined":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Mensagem","rules":"required","vid":"form.message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter-value":function (value) { return _vm.getMessageLenth(value); },"error-messages":errors,"counter":"160","hint":"Para utilizar uma variável, insira-a entre chaves {}, como no exemplo: {NOME}.","persistent-hint":"","label":"Mensagem (A variável {NUMERO} é reservada para o número do destinatário)","outlined":"","dense":""},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.form.variables,"disabled":"","chips":"","label":"Variáveis utilizadas","multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"info","label":"","small":""}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.form.variables),callback:function ($$v) {_vm.$set(_vm.form, "variables", $$v)},expression:"form.variables"}})],1)],1)],1),_c('v-col',{staticClass:"d-sm-flex justify-center position-relative",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"phone"},[_c('div',{staticClass:"speaker"}),_c('div',{staticStyle:{"width":"425px","height":"771px"}},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"chat"},[_c('div',{staticClass:"messages",attrs:{"id":"chat"}},[(_vm.messageChat)?_c('div',{staticClass:"message parker"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageChat)}})]):_vm._e()]),_c('div',{staticClass:"input"},[_c('input',{attrs:{"placeholder":"Enviar mensagem...","disabled":""}})])])])])])])],1)],1)],1),_c('dialog-remove',{attrs:{"status":_vm.removeDialogStatus,"text-to-confirm":_vm.form.name},on:{"action":_vm.fetchActionRemove,"close":function($event){_vm.removeDialogStatus = !_vm.removeDialogStatus}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }